import React from 'react'
import clsx from 'clsx'
import {
  Typography,
  makeStyles,
  createStyles,
  Container,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Link from '../Link/Link'
import { useMatomo } from '@datapunt/matomo-tracker-react'

type Tprops = {
  homePage?: any
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(6, 0),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2, 0),
      },
    },
    loading: {
      pointerEvents: 'none',
      opacity: 0.5,
      userSelect: 'none',
    },
    list: {
      padding: theme.spacing(2, 0),
      margin: 0,
      whiteSpace: 'nowrap',
      listStyle: 'none',
      display: 'block',
      overflowX: 'auto',
      overflowY: 'hidden',
      WebkitOverflowScrolling: 'touch',
      '&> li': {
        display: 'inline-block',
        marginRight: theme.spacing(1),
        [theme.breakpoints.up('lg')]: {
          marginRight: theme.spacing(3),
        },
        '& a': {
          color: theme.palette.primary.main,
          '&:hover, &:focus': {
            color: theme.palette.primary.dark,
          },
        },
      },
    },
    cta: {
      color: theme.palette.common.black,
      '&:hover, &:focus': {
        color: theme.palette.secondary.main,
      },
    },
  })
)

const MenuLink = (props) => {
  const { trackEvent } = useMatomo()
  return (
    <Link href={props.link?.url ? props.link?.url : '/'}>
      <a
        className={'section-menu__link'}
        target={props.link?.target === '_blank' ? '_blank' : '_self'}
      >
        <Typography
          component={'span'}
          onClick={() =>
            trackEvent({
              category: 'HomePage',
              href: props.link?.url ? props.link?.url : '/',
              action: `CTA click ${props.label}`,
              name: `from Menu section to ${
                props.link?.url ? props.link?.url : '/'
              }`,
            })
          }
        >
          {props.label}
        </Typography>
      </a>
    </Link>
  )
}

const MenuSection = ({ homePage }: Tprops) => {
  const classes = useStyles()
  const theme = useTheme()
  const list = homePage?.menu_list
  const menuCTALink = homePage?.menu_cta_link
  const menuCTALabel = homePage?.menu_cta_label
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { trackEvent } = useMatomo()

  return (
    <>
      <section
        className={clsx('section-menu', 'section__spacer', classes.root)}
      >
        <Container maxWidth="xl">
          {menuCTALink.url !== undefined ? (
            isMobile ? (
              <ul className={classes.list}>
                {list.map((el, index) => {
                  return (
                    el.label && (
                      <li key={index}>
                        <MenuLink link={el.link} label={el.label} />
                      </li>
                    )
                  )
                })}
                <li>
                  <Link href={menuCTALink.url}>
                    <a
                      target={
                        menuCTALink?.target === '_blank' ? '_blank' : '_self'
                      }
                    >
                      <Typography
                        component={'span'}
                        className={`section-menu__cta ${classes.cta}`}
                        onClick={() =>
                          trackEvent({
                            category: 'HomePage',
                            href: menuCTALink.url,
                            action: `CTA click ${menuCTALabel}`,
                            name: `from Menu section to ${menuCTALink.url}`,
                          })
                        }
                      >
                        {menuCTALabel}
                      </Typography>
                    </a>
                  </Link>
                </li>
              </ul>
            ) : (
              <Grid
                container
                spacing={2}
                justify={'space-between'}
                alignItems={'center'}
              >
                <Grid item xs={9} lg={'auto'}>
                  <ul className={classes.list}>
                    {list.map((el, index) => {
                      return (
                        el.label && (
                          <li key={index}>
                            <MenuLink link={el.link} label={el.label} />
                          </li>
                        )
                      )
                    })}
                  </ul>
                </Grid>
                <Grid item xs={3} lg={'auto'}>
                  <Link href={menuCTALink.url}>
                    <a
                      target={
                        menuCTALink?.target === '_blank' ? '_blank' : '_self'
                      }
                    >
                      <Typography
                        component={'span'}
                        className={`section-menu__cta ${classes.cta}`}
                        onClick={() =>
                          trackEvent({
                            category: 'HomePage',
                            href: menuCTALink.url,
                            action: `CTA click ${menuCTALabel}`,
                            name: `from Menu section to ${menuCTALink.url}`,
                          })
                        }
                      >
                        {menuCTALabel}
                      </Typography>
                    </a>
                  </Link>
                </Grid>
              </Grid>
            )
          ) : (
            <ul className={clsx(classes.list, 'section-list')}>
              {list.map((el, index) => {
                return (
                  el.label && (
                    <li key={index}>
                      <MenuLink key={index} link={el.link} label={el.label} />
                    </li>
                  )
                )
              })}
            </ul>
          )}
        </Container>
      </section>
    </>
  )
}

export default MenuSection
