import React, { useEffect } from 'react'
import {
  makeStyles,
  createStyles,
  Typography,
  Grid,
  Container,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import clsx from 'clsx'
import Link from '../Link/Link'
import { connect } from 'react-redux'
import { getPackTaxons } from '../../redux/actions/packs'
import { getTaxons } from '../../redux/actions/taxons'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SwiperOptions } from 'swiper/types/swiper-options'
import SwiperCore, { Pagination, Navigation, A11y } from 'swiper'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { columnLayout, columnSpacing, thumbnailRatio } from '../../utils/utils'
import Button from '../Button/Button'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import Skeleton from '@material-ui/lab/Skeleton'
import CMSText, { isPrismicText, prismicText } from '../Global/CMSText'
import { DiscoverCard } from './DiscoverCard'
import { PrismicHomePage } from '../../interfaces/common/prismicHomePage'
import { RootState } from '../../redux/reducers/rootReducer'

SwiperCore.use([Navigation, Pagination, A11y])

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      textAlign: 'left',
      padding: theme.spacing(6, 0),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0),
      },
      '& .swiper-slide': {
        marginBottom: theme.spacing(4),
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      margin: theme.spacing(2),
    },
    cardLink: {
      display: 'block',
    },
    taxonTitle: {
      padding: theme.spacing(2, 0),
      textAlign: 'center',
    },
    mainTitle: {
      width: '100%',
      textAlign: 'center',
      padding: theme.spacing(4, 0),
    },
    subtitle: {
      padding: theme.spacing(2, 0),
    },
    background: {
      //background: `linear-gradient(110deg, ${theme.palette.common.white} 60%, ${theme.palette.primaryTransparent.light} 60%)`,
    },
    fullwidth: {
      position: 'relative',
      width: '100vw',
      transform: 'translateX(-50%) translateY(0)',
      left: '50%',
    },
    col: {},
    md5: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: '20%',
        flexBasis: '20%',
      },
    },
    swiperArrows: {
      position: 'relative',
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(0, 6),
      },
    },
    swiperNoArrows: {},
    swiperArrow: {
      zIndex: 2,
      padding: 0,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      minWidth: 32,
      width: 32,
      height: 32,
      background: theme.palette.common.white,
      '&[disabled]': {
        opacity: 0.3,
        cursor: 'default',
      },
      '&#swiper-taxon__prev, &#swiper-taxonPack__prev, &#swiper-products__prev':
        {
          left: theme.spacing(1),
        },
      '&#swiper-taxon__next, &#swiper-taxonPack__next, &#swiper-products__next':
        {
          right: theme.spacing(1),
        },
    },
    skeletonImg: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      borderRadius: theme.shape.borderRadius,
    },
    footer: {
      textAlign: 'center',
    },
  })
)

const mapStateToProps = (state: RootState) => ({
  taxons: state.taxons,
  taxonPack: state.taxonPack,
})

const mapDispatchToProps = {
  getTaxons: getTaxons,
  getPackTaxons: getPackTaxons,
}

type Tprops = {
  homePage?: PrismicHomePage
  settings?: any
  getTaxons: any
  getPackTaxons: any
  taxons: any
  t?: any
  taxonLimit?: any
  isPack: boolean
  taxonPack: any
}

const Discover = ({
  homePage,
  settings,
  getTaxons,
  getPackTaxons,
  isPack,
  taxonLimit,
  taxonPack,
  taxons,
}: Tprops) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isMobileColumn = useMediaQuery(theme.breakpoints.down('xs'))
  const aspectRatio = thumbnailRatio(settings?.thumbnail_ratio)
  const thumbWidth = settings?.medium_image_size
  const gridItemWidth = columnLayout(homePage?.data?.classic_grid_columns)
  const gridSpacing = columnSpacing(homePage?.data?.carousel_cards_spacing)
  const isArrows = homePage?.data?.univers_carousel_arrows
  const isFiveColumns = gridItemWidth === '20%'
  const { trackEvent } = useMatomo()
  const isTaxonsChildren = taxons?.items?.[0]?.children?.length > 0

  const swiperParamsMobile: SwiperOptions = {
    centeredSlides: true,
    spaceBetween: 0,
    loop: true,
    slidesPerView: 1.25,
  }

  const swiperParamsDesktop: SwiperOptions = {
    spaceBetween: theme.spacing(gridSpacing),
    centeredSlides: false,
    loop: false,
  }

  const swiperParamsTaxonsM: SwiperOptions = {
    ...swiperParamsMobile,
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView:
          isTaxonsChildren && taxons.items[0].children.length < 4
            ? taxons.items[0].children.length
            : 4,
      },
      1680: {
        slidesPerView:
          isTaxonsChildren && taxons.items[0].children.length < 6
            ? taxons.items[0].children.length
            : 6,
      },
    },
    navigation: {
      nextEl: '#swiper-taxon__next',
      prevEl: '#swiper-taxon__prev',
    },
  }

  const swiperParamsTaxonsD: SwiperOptions = {
    ...swiperParamsDesktop,
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView:
          isTaxonsChildren && taxons.items[0].children.length < 4
            ? taxons.items[0].children.length
            : 4,
      },
      1680: {
        slidesPerView:
          isTaxonsChildren && taxons.items[0].children.length < 6
            ? taxons.items[0].children.length
            : 6,
      },
    },
    navigation: {
      nextEl: '#swiper-taxon__next',
      prevEl: '#swiper-taxon__prev',
    },
  }

  const swiperParamsTaxonsPacksM: SwiperOptions = {
    ...swiperParamsMobile,
    navigation: {
      nextEl: '#swiper-taxonPack__next',
      prevEl: '#swiper-taxonPack__prev',
    },
  }

  const swiperParamsTaxonsPacksD: SwiperOptions = {
    ...swiperParamsDesktop,
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView:
          isTaxonsChildren && taxons.items[0].children.length < 4
            ? taxons.items[0].children.length
            : 4,
      },
      1680: {
        slidesPerView:
          isTaxonsChildren && taxons.items[0].children.length < 6
            ? taxons.items[0].children.length
            : 6,
      },
    },
    navigation: {
      nextEl: '#swiper-taxonPack__next',
      prevEl: '#swiper-taxonPack__prev',
    },
  }

  const swiperParamsProductsM: SwiperOptions = {
    ...swiperParamsMobile,
    navigation: {
      nextEl: '#swiper-products__next',
      prevEl: '#swiper-products__prev',
    },
  }

  const swiperParamsProductsD: SwiperOptions = {
    ...swiperParamsDesktop,
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView:
          homePage?.data?.carousel_products &&
          homePage?.data?.carousel_products.length < 4
            ? homePage?.data?.carousel_products.length
            : 4,
      },
      1680: {
        slidesPerView:
          homePage?.data?.carousel_products &&
          homePage?.data?.carousel_products.length < 6
            ? homePage?.data?.carousel_products.length
            : 6,
      },
    },
    navigation: {
      nextEl: '#swiper-products__next',
      prevEl: '#swiper-products__prev',
    },
  }

  useEffect(() => {
    getTaxons()
    getPackTaxons()
  }, [])

  const SwiperTaxons = ({ params }) => (
    <Swiper {...params}>
      {isTaxonsChildren &&
        taxons.items[0].children.map((taxon, index) => {
          if (index < prismicText(taxonLimit)) {
            return (
              <SwiperSlide key={index}>
                <DiscoverCard
                  isCarousel
                  url={'/shop'}
                  name={taxon.name}
                  taxonCat={taxon.slug}
                  img={
                    taxon?.images && taxon?.images?.length > 0
                      ? taxon?.images[0]
                      : ''
                  }
                  ratio={aspectRatio}
                  thumbWidth={thumbWidth}
                />
              </SwiperSlide>
            )
          }
        })}
    </Swiper>
  )

  const SwiperProducts = ({ params }) => (
    <Swiper {...params}>
      {homePage.data?.carousel_products.map((carousel_product, index) => {
        return (
          <SwiperSlide key={index}>
            <DiscoverCard
              name={prismicText(carousel_product?.title)}
              productPrice={prismicText(carousel_product?.price)}
              url={
                carousel_product?.link?.url ? carousel_product?.link?.url : '/'
              }
              img={carousel_product?.image?.url}
              isProduct
              isCarousel
              ratio={aspectRatio}
            />
          </SwiperSlide>
        )
      })}
    </Swiper>
  )

  const SwiperTaxonPacks = ({ params }) => (
    <Swiper {...params}>
      {typeof taxonPack.items !== 'undefined' &&
        taxonPack.items.length > 0 &&
        taxonPack.items?.map((taxon) => {
          return (
            <SwiperSlide key={taxon.code}>
              <DiscoverCard
                isCarousel
                url={'/packs'}
                name={taxon.name}
                taxonCat={taxon.code}
                img={
                  taxon?.images && taxon?.images?.length > 0
                    ? taxon?.images[0]
                    : ''
                }
                ratio={aspectRatio}
              />
            </SwiperSlide>
          )
        })}
    </Swiper>
  )

  return (
    <div className={classes.background}>
      <section className={`section-discover section__spacer ${classes.root}`}>
        <Container maxWidth={'xl'} className={classes.container}>
          {homePage?.data?.enable_taxon_product && (
            <div
              style={{
                order: homePage?.data?.position_product_category || 1,
              }}
            >
              {isPrismicText(homePage?.data?.title_universe) && (
                <div className={`section-discover__head ${classes.mainTitle}`}>
                  <Typography
                    variant={'h2'}
                    component={'h2'}
                    color={'primary'}
                    className={`section-discover__title ${classes.title}`}
                  >
                    {prismicText(homePage?.data?.title_universe)}
                  </Typography>
                </div>
              )}
              {homePage?.data?.subtitle_universe1.length !== 0 && (
                <div
                  className={clsx(
                    'section-discover__subtext',
                    'section-discover__subtext--categories',
                    classes.subtitle
                  )}
                >
                  <Typography
                    variant={'h2'}
                    color={'textPrimary'}
                    className={`section-discover__subtitle ${classes.title}`}
                  >
                    <CMSText data={homePage?.data?.subtitle_universe1} asText />
                  </Typography>
                  <Typography
                    variant={'body1'}
                    color={'textPrimary'}
                    className={`section-discover__desc ${classes.title}`}
                  >
                    {' '}
                    <CMSText
                      data={homePage?.data?.paragraph_universe1}
                      asText
                    />
                  </Typography>
                </div>
              )}
              {homePage?.data?.univers_layout ? (
                <div className={clsx('fullwidth', classes.fullwidth)}>
                  <div
                    className={
                      isArrows ? classes.swiperArrows : classes.swiperNoArrows
                    }
                  >
                    <>
                      {isArrows && (
                        <>
                          <Button
                            className={classes.swiperArrow}
                            id="swiper-taxon__prev"
                          >
                            <KeyboardArrowLeft />
                          </Button>
                          <Button
                            className={classes.swiperArrow}
                            id="swiper-taxon__next"
                          >
                            <KeyboardArrowRight />
                          </Button>
                        </>
                      )}
                      {isMobile ? (
                        <SwiperTaxons params={swiperParamsTaxonsM} />
                      ) : (
                        <SwiperTaxons params={swiperParamsTaxonsD} />
                      )}
                    </>
                  </div>
                </div>
              ) : (
                <Grid container spacing={3} justify={'center'}>
                  {taxons?.loading !== true &&
                  taxons?.items &&
                  taxons?.items[0]?.children?.length > 0
                    ? taxons.items[0].children.map((taxon, index) => {
                        if (index < prismicText(taxonLimit)) {
                          return (
                            <Grid
                              item
                              key={index}
                              xs={6}
                              lg={isFiveColumns ? 2 : gridItemWidth}
                              className={clsx(
                                'section-discover__col',
                                `section-discover__col--${taxon.slug}`,
                                isFiveColumns ? classes.md5 : classes.col,
                                isMobileColumn &&
                                  'section-discover__col--mobile'
                              )}
                            >
                              <>
                                <DiscoverCard
                                  url={'/shop'}
                                  name={taxon.name}
                                  taxonCat={taxon.slug}
                                  img={
                                    taxon?.images && taxon?.images?.length > 0
                                      ? taxon?.images[0]
                                      : ''
                                  }
                                  ratio={aspectRatio}
                                  thumbWidth={thumbWidth}
                                />
                              </>
                            </Grid>
                          )
                        }
                      })
                    : [0, 1, 2].map((index) => {
                        return (
                          <Grid
                            item
                            key={index}
                            xs={6}
                            lg={isFiveColumns ? 2 : gridItemWidth}
                          >
                            <div>
                              <Typography
                                variant={'h3'}
                                color={'primary'}
                                className={`discover__title ${classes.taxonTitle}`}
                              >
                                <Skeleton variant={'text'} />
                              </Typography>
                              <div data-ratio={aspectRatio}>
                                <Skeleton
                                  variant="rect"
                                  className={classes.skeletonImg}
                                />
                              </div>
                            </div>
                          </Grid>
                        )
                      })}
                </Grid>
              )}
            </div>
          )}
          {homePage?.data?.enable_products_carousel && (
            <div
              style={{
                order: homePage?.data?.position_product_carrousel || 2,
              }}
            >
              {homePage?.data?.subtitle_universe3.length !== 0 && (
                <div
                  className={`section-discover__subtext--products ${classes.subtitle}`}
                >
                  <Typography
                    variant={'h2'}
                    color={'textPrimary'}
                    className={`section-discover__subtitle ${classes.title}`}
                  >
                    <CMSText data={homePage?.data?.subtitle_universe3} />
                  </Typography>
                  <Typography
                    variant={'body1'}
                    color={'textPrimary'}
                    className={`section-discover__desc ${classes.title}`}
                  >
                    {' '}
                    {prismicText(homePage?.data?.paragraph_universe3)}
                  </Typography>
                </div>
              )}
              {homePage?.data?.univers_layout ? (
                <div className={clsx('fullwidth', classes.fullwidth)}>
                  <div
                    className={
                      isArrows ? classes.swiperArrows : classes.swiperNoArrows
                    }
                  >
                    <>
                      {isArrows && (
                        <>
                          <Button
                            className={classes.swiperArrow}
                            id="swiper-products__prev"
                          >
                            <KeyboardArrowLeft />
                          </Button>
                          <Button
                            className={classes.swiperArrow}
                            id="swiper-products__next"
                          >
                            <KeyboardArrowRight />
                          </Button>
                        </>
                      )}
                      {isMobile ? (
                        <SwiperProducts params={swiperParamsProductsM} />
                      ) : (
                        <SwiperProducts params={swiperParamsProductsD} />
                      )}
                    </>
                  </div>
                </div>
              ) : (
                <Grid container spacing={3} justify={'center'}>
                  {homePage?.data?.carousel_products.map(
                    (carousel_product, index) => {
                      return (
                        <Grid
                          item
                          key={index}
                          xs={6}
                          lg={isFiveColumns ? 2 : gridItemWidth}
                          className={clsx(
                            isFiveColumns ? classes.md5 : classes.col,
                            'section-discover__col'
                          )}
                        >
                          <DiscoverCard
                            name={prismicText(carousel_product?.title)}
                            productPrice={prismicText(carousel_product?.price)}
                            url={carousel_product?.link?.url || '/'}
                            img={carousel_product?.image?.url}
                            isProduct
                            ratio={aspectRatio}
                          />
                        </Grid>
                      )
                    }
                  )}
                </Grid>
              )}
            </div>
          )}
          {isPack && homePage?.data?.enable_taxon_pack && (
            <div
              style={{
                order: homePage?.data?.position_pack_category || 3,
              }}
            >
              {homePage?.data?.subtitle_universe1.length !== 0 && (
                <div
                  className={clsx(
                    'section-discover__subtext',
                    'section-discover__subtext--packs',
                    classes.subtitle
                  )}
                >
                  <Typography
                    variant={'h2'}
                    color={'textPrimary'}
                    className={`section-discover__subtitle ${classes.title}`}
                  >
                    <CMSText data={homePage.data.subtitle_universe2} />
                  </Typography>
                  <Typography
                    variant={'body1'}
                    color={'textPrimary'}
                    className={`section-discover__desc ${classes.title}`}
                  >
                    {prismicText(homePage?.data?.paragraph_universe2)}
                  </Typography>
                </div>
              )}
              {homePage?.data?.univers_layout ? (
                <div className={clsx('fullwidth', classes.fullwidth)}>
                  <div
                    className={
                      isArrows ? classes.swiperArrows : classes.swiperNoArrows
                    }
                  >
                    <>
                      {isArrows && (
                        <>
                          <Button
                            className={classes.swiperArrow}
                            id="swiper-taxonPack__prev"
                          >
                            <KeyboardArrowLeft />
                          </Button>
                          <Button
                            className={classes.swiperArrow}
                            id="swiper-taxonPack__next"
                          >
                            <KeyboardArrowRight />
                          </Button>
                        </>
                      )}
                      {isMobile ? (
                        <SwiperTaxonPacks params={swiperParamsTaxonsPacksM} />
                      ) : (
                        <SwiperTaxonPacks params={swiperParamsTaxonsPacksD} />
                      )}
                    </>
                  </div>
                </div>
              ) : (
                <Grid container spacing={3} justify={'center'}>
                  {taxonPack?.loading !== true &&
                  taxonPack?.items &&
                  taxonPack.items?.length > 0
                    ? taxonPack.items?.map((taxon, index) => {
                        if (
                          index <
                          parseInt(
                            prismicText(homePage?.data?.pack_taxon_limit_number)
                          )
                        ) {
                          return (
                            <Grid
                              item
                              key={taxon.code}
                              xs={6}
                              lg={isFiveColumns ? 2 : gridItemWidth}
                              className={
                                isFiveColumns ? classes.md5 : classes.col
                              }
                            >
                              <DiscoverCard
                                url={'/packs'}
                                name={taxon.name}
                                taxonCat={taxon.code}
                                img={
                                  taxon?.images && taxon?.images?.length > 0
                                    ? taxon?.images[0]
                                    : ''
                                }
                                ratio={aspectRatio}
                              />
                            </Grid>
                          )
                        }
                      })
                    : [0, 1, 2].map((index) => {
                        return (
                          <Grid
                            item
                            key={index}
                            xs={6}
                            lg={isFiveColumns ? 2 : gridItemWidth}
                          >
                            <div>
                              <Typography
                                variant={'h3'}
                                color={'primary'}
                                className={`discover__title ${classes.taxonTitle}`}
                              >
                                <Skeleton variant={'text'} />
                              </Typography>
                              <div data-ratio={aspectRatio}>
                                <Skeleton
                                  variant="rect"
                                  className={classes.skeletonImg}
                                />
                              </div>
                            </div>
                          </Grid>
                        )
                      })}
                </Grid>
              )}
            </div>
          )}
          {isPrismicText(homePage?.data?.univers_cta_label) &&
            homePage?.data?.univers_cta_link?.url && (
              <div className={clsx('section-discover__footer', classes.footer)}>
                <Link href={homePage?.data?.univers_cta_link?.url} scroll>
                  <a
                    className={clsx('discover__cta', classes.cardLink)}
                    onClick={() =>
                      trackEvent({
                        category: 'HomePage',
                        href: `${homePage?.data?.univers_cta_link?.url}`,
                        action: `Click on ${prismicText(
                          homePage?.data?.univers_cta_label
                        )}`,
                        name: `Universe Section to ${homePage?.data?.univers_cta_link?.url}`,
                      })
                    }
                  >
                    <Button
                      color={'primary'}
                      variant={'contained'}
                      component={'span'}
                    >
                      {prismicText(homePage?.data?.univers_cta_label)}
                    </Button>
                  </a>
                </Link>
              </div>
            )}
        </Container>
      </section>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Discover)
