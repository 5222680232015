import {
  Typography,
  makeStyles,
  createStyles,
  Container,
} from '@material-ui/core'
import React, { useState } from 'react'
import FaqQuestion from '../Faq/FaqQuestion'
import Button from '../Button/Button'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import CMSText, { isPrismicText } from '../Global/CMSText'
import { useTranslation } from 'react-i18next'
import Link from '../Link/Link'

type Tprops = {
  doc?: any
  isTakeBack?: boolean
  page: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(6, 0),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2, 0),
      },
    },
    align_center: {
      textAlign: 'center',
      margin: 'auto',
    },
    padding_top: {
      paddingTop: theme.spacing(3),
    },
    spacing: {
      padding: theme.spacing(8),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0),
      },
    },
    faqItem: {
      marginBottom: theme.spacing(2),
    },
  })
)

const FaqSection = ({ doc, isTakeBack, page }: Tprops) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const faqs = doc?.data?.q_a
  const faqTitle = doc?.data?.faq_title
  const faqDesc = doc?.data?.faq_description
  const faqCTALink = doc?.data?.faq_cta
  const faqCTALabel = doc?.data?.faq_cta_label
  const { trackEvent } = useMatomo()

  const [isExpanded, setIsExpanded] = useState({ cat: null, question: null })

  const handleExpandClick = (question, cat) => {
    if (isExpanded.question === question) {
      setIsExpanded({ cat: null, question: null })
    } else {
      setIsExpanded({ cat: cat, question: question })
    }
  }

  return (
    <>
      <section
        className={`${
          isTakeBack ? 'section-faq-tb' : 'section-faq'
        } section__spacer ${classes.root}`}
      >
        <Container>
          <div className={`${classes.align_center}`}>
            {faqTitle?.length > 0 && faqTitle[0]?.text.length > 0 && (
              <Typography
                color={'primary'}
                variant={'h2'}
                className={`section-faq__title ${classes.padding_top}`}
              >
                <CMSText
                  asText
                  data={faqTitle}
                  defaultText={t('texts:general:FAQ')}
                />
              </Typography>
            )}
            {faqDesc?.length > 0 && faqDesc[0]?.text.length > 0 && (
              <Typography
                variant={'body1'}
                className={`section-faq__subtitle ${classes.padding_top}`}
                component={'div'}
              >
                <CMSText data={faqDesc} />
              </Typography>
            )}
            {faqs && faqs.length !== 0 && (
              <div className={classes.padding_top}>
                <Container>
                  {faqs?.map((faq, index) => (
                    <div key={index} className={`faq__item ${classes.faqItem}`}>
                      <FaqQuestion
                        question={faq.question}
                        questionId={index}
                        isExpanded={isExpanded.question === index}
                        handleExpandClick={handleExpandClick}
                        close_others={doc?.data?.close_others_on_click}
                        answer={faq.answer}
                      />
                    </div>
                  ))}
                </Container>
              </div>
            )}
            {isPrismicText(faqCTALabel) && (
              <Link href={faqCTALink?.url ? faqCTALink?.url : ''}>
                <a
                  target={faqCTALink?.target === '_blank' ? '_blank' : '_self'}
                >
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    className={'section-faq__cta'}
                    onClick={() =>
                      trackEvent({
                        category: page,
                        action: `click text`,
                        name: `${faqCTALink?.url}`,
                      })
                    }
                  >
                    <CMSText data={faqCTALabel} />
                  </Button>
                </a>
              </Link>
            )}
          </div>
        </Container>
      </section>
    </>
  )
}

export default FaqSection
