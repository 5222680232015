import React from 'react'
import { makeStyles, createStyles, Typography } from '@material-ui/core'
import Link from '../Link/Link'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import ImgKitHandler from '../ImgKitHandler/ImgKitHandler'
import Image from 'next/image'

const useStyles = makeStyles((theme) =>
  createStyles({
    img: {
      display: 'block',
      '&[src=""]': {
        content: "url('./no_image.jpg')",
      },
    },
    cardLink: {
      display: 'block',
    },
    card: {
      position: 'relative',
      '& .MuiTypography-root': {
        zIndex: 2,
        position: 'absolute',
        bottom: theme.spacing(1),
        left: theme.spacing(1),
        fontSize: theme.typography.pxToRem(14),
        padding: theme.spacing(1, 2),
        background: 'rgba(255, 255, 255, .75)',
      },
    },
    shadow: {
      background: theme.palette.common.white,
      boxShadow: theme.shadows[2],
      transition: theme.transitions.create('box-shadow'),
      cursor: 'pointer',
      '&:hover': {
        boxShadow: theme.shadows[10],
      },
    },
    taxonTitle: {
      padding: theme.spacing(2, 0),
      textAlign: 'center',
    },
    mainTitle: {
      width: '100%',
      textAlign: 'center',
      padding: theme.spacing(4, 0),
    },
    subtitle: {
      padding: theme.spacing(2, 0),
    },
    md5: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: '20%',
        flexBasis: '20%',
      },
    },
    cardProduct: {
      position: 'relative',
    },
    cardProductContent: {
      zIndex: 2,
      position: 'absolute',
      bottom: theme.spacing(1),
      left: theme.spacing(1),
      padding: theme.spacing(1, 2),
      background: 'rgba(255, 255, 255, .75)',
    },
    shadowProductContent: {
      textAlign: 'center',
      padding: theme.spacing(1, 2, 0.5),
    },
  })
)

type DiscoverCardProps = {
  url: string
  name: string
  img: string
  productPrice?: string
  taxonCat?: string
  isCarousel?: boolean
  isProduct?: boolean
  ratio?: string
  thumbWidth?: number
}

export const DiscoverCard = ({
  url,
  name,
  img,
  productPrice,
  taxonCat,
  isCarousel,
  isProduct,
  ratio,
  thumbWidth,
}: DiscoverCardProps) => {
  const classes = useStyles()
  const { trackEvent } = useMatomo()

  return !isProduct ? (
    <Link
      href={{
        pathname: url === '/shop' ? `${taxonCat}` : `/packs`,
        query: url !== '/shop' && { cat: taxonCat },
      }}
      scroll
    >
      <a
        className={`discover ${classes.cardLink}`}
        onClick={() =>
          trackEvent({
            category: 'HomePage',
            href: `/${taxonCat}`,
            action: `Universe Section to ${taxonCat}`,
          })
        }
      >
        <div
          className={
            isCarousel
              ? `discover__card ${classes.card}`
              : `discover__shadow ${classes.shadow}`
          }
        >
          <Typography
            variant={'h3'}
            color={'primary'}
            className={`discover__title ${classes.taxonTitle}`}
          >
            {name}
          </Typography>
          <div data-ratio={ratio} className={'discover__media'}>
            <ImgKitHandler
              classes={classes.img}
              imagePaths={img}
              alt=""
              settings={{
                quality: 75,
                width: thumbWidth,
                ar: ratio?.substring(3),
              }}
            />
          </div>
        </div>
      </a>
    </Link>
  ) : (
    <Link href={url} scroll>
      <a
        className={`discover ${classes.cardLink}`}
        onClick={() =>
          trackEvent({
            category: 'Select Product',
            action: `Select ${name}`,
            name: `/`,
          })
        }
      >
        <div
          className={
            isCarousel
              ? `discover__card ${classes.cardProduct}`
              : `discover__shadow ${classes.shadow}`
          }
        >
          {name && (
            <div
              className={
                isCarousel
                  ? `discover-product__content ${classes.cardProductContent}`
                  : `discover-product__content ${classes.shadowProductContent}`
              }
            >
              <Typography
                variant={'h5'}
                component={'h2'}
                color={'primary'}
                className={`discover-product__title`}
              >
                {name}
              </Typography>
              {productPrice && (
                <Typography
                  variant={'caption'}
                  color={'primary'}
                  className={`discover-product__price`}
                >
                  {productPrice}
                </Typography>
              )}
            </div>
          )}
          <div data-ratio={ratio} className={'discover__media'}>
            {img && (
              <Image src={img} alt={''} className={classes.img} layout="fill" />
            )}
          </div>
        </div>
      </a>
    </Link>
  )
}
