import React, { useEffect, useMemo, useState } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { Taxon } from '@/typings/base'
import { RootState } from '@/redux/reducers/rootReducer'
import { connect } from 'react-redux'
import { getLocalesTaxons } from '../redux/services/taxons'
import { translateUrlSlugs } from '../utils/utils'

const mapStateToProp = (state: RootState) => {
  return {
    taxons: state.taxons.items,
  }
}

type HreflangLinksProps = {
  locales: string[]
} & ReturnType<typeof mapStateToProp>

const HreflangLinks: React.FC<HreflangLinksProps> = ({ locales, taxons }) => {
  const router = useRouter()
  const { asPath, defaultLocale, locale } = router
  const category = router?.query?.slug

  const [allTaxons, setAllTaxons] = useState<Map<string, Taxon[]>>(new Map())

  const uniqueLocales = useMemo(
    () =>
      Array.from(new Set(locales.map((loc) => loc.split('-')[0])))
        .map((uniqueLoc) => locales.find((loc) => loc.startsWith(uniqueLoc)))
        .filter((loc): loc is string => !!loc),
    [locales]
  )

  const [currentOrigin, setCurrentOrigin] = useState<string | null>(null)

  const defaultLang = useMemo(
    () => uniqueLocales.find((lang) => lang.includes(defaultLocale)),
    [defaultLocale, uniqueLocales]
  )

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrentOrigin(window.location.origin)
    }
  }, [])

  useEffect(() => {
    if (
      !router.isReady ||
      taxons.length === 0 ||
      locales.length === 1 ||
      !category
    )
      return

    setAllTaxons(new Map([[locale, taxons[0]?.children || []]]))
    const fetchTaxons = async (localesToFetch: string[]) => {
      const fetchedTaxons = await getLocalesTaxons(localesToFetch)
      setAllTaxons(
        new Map([
          ...Object.entries(fetchedTaxons),
          [locale, taxons[0]?.children || []],
        ])
      )
    }

    fetchTaxons(
      uniqueLocales
        .map((loc) => loc.split('-')[0])
        .filter((loc) => loc !== locale)
    )
  }, [locale, locales, taxons, router.isReady, uniqueLocales])

  const hreflangLinks = useMemo(() => {
    const links: {
      href: string
      hreflang: string
    }[] = []

    const slugs = category
      ? Array.isArray(category)
        ? [...category]
        : [category]
      : []

    locales.forEach((loc) => {
      const isDefault = loc === defaultLang
      const currentLocale = loc.split('-')[0]
      if (isDefault) {
        let href = `${currentOrigin}${asPath}`
        if (
          currentLocale !== locale &&
          slugs?.length > 0 &&
          allTaxons.size > 1
        ) {
          href = translateUrlSlugs(
            href,
            [...slugs],
            allTaxons.get(locale),
            allTaxons.get(currentLocale)
          )
        }
        links.push({
          href: href.endsWith('/') ? href.slice(0, -1) : href,
          hreflang: 'x-default',
        })
      }
      let href = `${currentOrigin}${
        isDefault ? '' : `/${currentLocale}`
      }${asPath}`

      if (currentLocale !== locale && slugs?.length > 0 && allTaxons.size > 1) {
        href = translateUrlSlugs(
          href,
          [...slugs],
          allTaxons.get(locale),
          allTaxons.get(currentLocale)
        )
      }

      links.push({
        href: href.endsWith('/') ? href.slice(0, -1) : href,
        hreflang: loc,
      })
    })

    return links
  }, [locales, defaultLang, currentOrigin, asPath, locale, taxons, allTaxons])

  if (!currentOrigin || locales.length === 0 || !defaultLang) return null

  return (
    <Head>
      {hreflangLinks.map((link, index) => (
        <link
          key={index}
          rel="alternate"
          href={link.href}
          hrefLang={link.hreflang}
        />
      ))}
    </Head>
  )
}

export default connect(mapStateToProp)(HreflangLinks)
