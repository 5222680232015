import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles, Typography } from '@material-ui/core'
import Link from '../Link/Link'
import Image from 'next/image'
import Skeleton from '@material-ui/lab/Skeleton'
import { thumbnailRatio, truncateString } from '../../utils/utils'
import ImgKitHandler from '../ImgKitHandler/ImgKitHandler'
import { useTranslation } from 'react-i18next'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { prismicText } from '../Global/CMSText'

const useStyles = makeStyles((theme) =>
  createStyles({
    packCard: {
      position: 'relative',
      textAlign: 'left',
      maxWidth: 190,
      background: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
      margin: theme.spacing(0, 'auto', 3),
      [theme.breakpoints.up('md')]: {
        maxWidth: 220,
      },
    },
    packImage: {
      width: '100%',
      objectFit: 'contain',
    },
    alignCenter: {
      textAlign: 'center',
    },
    lineHeight: {
      lineHeight: 1.3,
      textAlign: 'center',
    },
    title: {
      marginTop: theme.spacing(1),
    },
    smallButton: {
      fontSize: theme.typography.pxToRem(12),
      padding: theme.spacing(0.5, 1),
      '& .MuiButton-endIcon': {
        marginLeft: 0,
      },
    },
    price: {
      fontSize: theme.typography.pxToRem(12),
      marginBottom: theme.spacing(2),
    },
    overlayLink: {
      position: 'absolute',
      zIndex: 2,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  })
)

type TProps = {
  isLoading?: boolean
  pack?: any
  isPack?: boolean
  price_pack?: any
  isCustom?: boolean
  thumbnail_ratio?: string
  text?: any
}

const PackCard = ({
  isLoading,
  isPack,
  price_pack,
  isCustom,
  pack,
  thumbnail_ratio,
  text,
}: TProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { trackEvent } = useMatomo()
  const aspectRatio = thumbnailRatio(thumbnail_ratio)
  const largeImgWidth = text?.large_image_size

  return isLoading ? (
    <div className={clsx('packCard', classes.packCard)}>
      <Skeleton variant="rect" width={'100%'} height={120} />
      <Skeleton variant="text" width={80} />
      <Skeleton variant="text" width={100} />
      <Skeleton variant="rect" width={'100%'} height={32} />
    </div>
  ) : (
    <div className={clsx('packCard', classes.packCard)}>
      <Link
        href={
          !isCustom
            ? isPack
              ? '/packs'
              : '/shop'
            : pack.link.url !== undefined
            ? pack.link.url
            : '/'
        }
      >
        <a
          className={classes.overlayLink}
          onClick={() =>
            trackEvent({
              category: 'HomePage',
              href: !isCustom
                ? isPack
                  ? '/packs'
                  : '/shop'
                : pack.link.url !== undefined
                ? pack.link.url
                : '/',
              action: `CTA click ${t('texts:homepage:rent')}`,
              name: `from Hero Product/Pack Card to ${
                !isCustom
                  ? isPack
                    ? '/packs'
                    : '/shop'
                  : pack.link.url !== undefined
                  ? pack.link.url
                  : '/'
              }`,
            })
          }
        >
          <Typography variant="srOnly">{t('texts:homepage:rent')}</Typography>
        </a>
      </Link>
      <div data-ratio={aspectRatio} className={classes.packImage}>
        {!isCustom ? (
          <ImgKitHandler
            alt={''}
            imagePaths={isPack && pack?.images.length > 0 ? pack.images[0] : ''}
            classes={classes.packImage}
            settings={{
              width: largeImgWidth,
              ar: aspectRatio?.substring(3),
            }}
          />
        ) : (
          pack?.image?.url && (
            <Image
              layout="fill"
              src={pack?.image?.url}
              alt={''}
              className={classes.packImage}
            />
          )
        )}
      </div>
      <Typography
        variant={'body1'}
        className={clsx('packCard__title', classes.title, classes.lineHeight)}
      >
        {!isCustom ? truncateString(pack.name, 100) : prismicText(pack?.desc)}
      </Typography>
      {price_pack && (
        <Typography
          variant={'body1'}
          color={'secondary'}
          className={clsx('packCard__price', classes.price, classes.lineHeight)}
          gutterBottom
        >
          <b>{price_pack}</b>
        </Typography>
      )}
    </div>
  )
}

export default PackCard
