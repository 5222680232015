import React, { useState } from 'react'
import {
  Container,
  createStyles,
  GridList,
  GridListTile,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Link from '../Link/Link'
import { useTranslation } from 'react-i18next'
import { useMatomo } from '@datapunt/matomo-tracker-react'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(6, 0),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2, 0),
      },
    },
    content: {
      maxWidth: 900 + theme.spacing(6),
      '& .MuiGridListTile-root': {
        transition: theme.transitions.create('opacity'),
        willChange: 'opacity',
      },
    },
    contentSmall: {
      maxWidth: 600 + theme.spacing(6),
    },
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    overlay: {
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    active: {
      opacity: 1,
      '& .MuiGridListTile-root': {
        opacity: 0.75,
        '&:hover': {
          opacity: 1,
        },
      },
    },
    inactive: {
      opacity: 1,
    },
  })
)

type Tprops = {
  data: any
}

const PartnerText = (props) => {
  const { trackEvent } = useMatomo()
  return (
    <Typography
      variant={'srOnly'}
      onClick={() =>
        trackEvent({
          category: 'HomePage',
          href: props.link,
          action: `Link click ${
            props.image.alt === null
              ? `${props.text} ${props.index + 1}`
              : props.image.alt
          }`,
          name: `from Partners section to ${props.link}`,
        })
      }
    >
      {props.image.alt === null
        ? `${props.text} ${props.index + 1}`
        : props.image.alt}
    </Typography>
  )
}

const PartnersSection = ({ data }: Tprops) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [active, setActive] = useState(false)
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const partners = data.partners_list
  const cols = partners.length <= 2 ? 2 : 3

  const handleActiveTiles = () => {
    setActive(true)
  }

  const handleInActiveTiles = () => {
    setActive(false)
  }

  return (
    <div className={`section-partners section__spacer ${classes.root}`}>
      <Container
        className={
          partners.length <= 2
            ? `${classes.content} ${classes.contentSmall}`
            : classes.content
        }
      >
        <GridList
          cellHeight={isTablet ? 'auto' : 300}
          cols={isTablet ? 2 : cols}
          spacing={0}
          className={active ? classes.active : classes.inactive}
          onMouseOver={() => handleActiveTiles()}
          onMouseOut={() => handleInActiveTiles()}
        >
          {partners.map((partner, index) => {
            const { link, image } = partner
            const linkType = link.link_type
            const linkTarget = link.target

            return (
              <GridListTile key={index}>
                <>
                  <Typography variant={'h2'}>
                    {linkType !== 'Any' ? (
                      linkType === 'Web' ? (
                        <Link href={link.url}>
                          <a
                            className={classes.overlay}
                            target={
                              linkTarget !== '_blank' ? '_self' : '_blank'
                            }
                          >
                            <PartnerText
                              image={image}
                              text={t('texts:homepage:partner')}
                              index={index}
                              link={link.url}
                            />
                          </a>
                        </Link>
                      ) : (
                        <Link href={`${link.type}/${link.uid}`}>
                          <a
                            className={classes.overlay}
                            target={
                              linkTarget !== '_blank' ? '_self' : '_blank'
                            }
                          >
                            <PartnerText
                              image={image}
                              text={t('texts:homepage:partner')}
                              index={index}
                              link={`${link.type}/${link.uid}`}
                            />
                          </a>
                        </Link>
                      )
                    ) : (
                      <PartnerText
                        image={image}
                        text={t('texts:homepage:partner')}
                        index={index}
                      />
                    )}
                  </Typography>
                  <img className={classes.img} src={image.url} alt="" />
                </>
              </GridListTile>
            )
          })}
        </GridList>
      </Container>
    </div>
  )
}

export default PartnersSection
