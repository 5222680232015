import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  Typography,
  Container,
  useMediaQuery,
  useTheme,
  Box,
  Grid,
} from '@material-ui/core'
import useHeroHomeStyle from './styles/HeroHome.style'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { connect } from 'react-redux'
import { getPacksByPackTaxonId, getPackTaxons } from '../../redux/actions/packs'
import Glider from 'react-glider'
import { scrollToDiv } from '../../utils/utils'
import Statements from '../Statements/Statements'
import Button from '../Button/Button'
import PackCard from './PackCard'
import DateRangeForm from '../DateRangeForm/DateRangeForm'
import { getLatestProducts } from '../../redux/actions/products'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import CMSText, { prismicText } from '../Global/CMSText'
import dynamic from 'next/dynamic'
import Link from '../Link/Link'
import { RootState } from '../../redux/reducers/rootReducer'
import { GetMapDispatchTypes } from '../../interfaces/common/redux'
import { PrismicHomePageData } from '../../interfaces/common/prismicHomePage'
import { PrismicGeneralData } from '../../interfaces/common/prismicGeneralConfig'
import { useTranslation } from 'react-i18next'
const HeroHomeImg = dynamic(() => import('./HeroHomeImg'))

const mapStateToProps = (state: RootState) => ({
  packs: state.packs,
  products: state.products,
})

const mapDispatchToProps = {
  getPackTaxons: getPackTaxons,
  getPacksByPackTaxonId: getPacksByPackTaxonId,
  getLatestProducts: getLatestProducts,
}

type Tprops = ReturnType<typeof mapStateToProps> &
  GetMapDispatchTypes<typeof mapDispatchToProps> & {
    children: React.ReactNode
    settings?: PrismicHomePageData
    text?: PrismicGeneralData
    statements?: any
    isProduct: boolean
    isEnableChooseStartingDate: boolean
  }

const HeroHome = ({
  settings,
  text,
  products,
  getLatestProducts,
  getPackTaxons,
  packs,
  isProduct,
  getPacksByPackTaxonId,
  statements,
  children,
  isEnableChooseStartingDate,
}: Tprops) => {
  const classes = useHeroHomeStyle()
  const { t } = useTranslation()
  const theme = useTheme()

  const prefersReducedMotion = useMediaQuery('(prefers-reduced-motion: reduce)')
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isDesktopXL = useMediaQuery(theme.breakpoints.up('xl'))

  const isSubscription =
    process.env.STORE_TYPE === 'subscription' ||
    process.env.STORE_TYPE === 'classic_subscription'

  const isPack = packs?.items?.length > 0

  // Matomo event tracking hook
  const { trackEvent } = useMatomo()

  // Retrieve configuration parameters with default values
  const {
    hero_text_color: heroTextColor,
    cta_pack_label: ctaPackLabel,
    pack_price: packPrice,
    show_statements: showStatements,
    show_featured_packs: showPacks,
    products: customProducts = [],
    mainimg: mainImg,
    enable_background_video_mobile: isVideoMobile,
    background_video_file: backgroundVideoFile,
    background_video_mobile: backgroundVideoMobile,
    hero_ctas: ctas,
    header_style: headerStyle,
    use_carousel: isUseCarousel,
  } = settings || {}

  const isCustomProducts = customProducts?.length > 0
  const textColor = heroTextColor ? heroTextColor : '#ffffff'
  const button_to_packs = prismicText(ctaPackLabel)
  const price_pack = prismicText(packPrice)
  const video = backgroundVideoFile?.url
  const videoMobile = backgroundVideoMobile?.url
  const thumbnail_ratio = text?.thumbnail_ratio
  const isTransparentMedia = headerStyle
    ? `hero__home-media--transparent ${classes.media} ${classes.mediaTransparent}`
    : `hero__home-media ${classes.media}`

  const [activePacks, setActivePacks] = useState(false)

  const handleActivePack = () => {
    setActivePacks(true)
  }

  const handleDesActivePack = () => {
    setActivePacks(false)
  }

  useEffect(() => {
    async function getTaxons() {
      const taxon = await getPackTaxons()
      if (typeof taxon !== 'undefined' && taxon?.taxonPack?.length > 0) {
        getPacksByPackTaxonId(taxon?.taxonPack[0]?.id)
      }
    }

    if (isProduct) {
      getLatestProducts()
    } else {
      getTaxons()
    }
  }, [])

  const layoutOpt = settings?.hero_layout?.toLowerCase() || 'left'

  const videoTag = (src: string) => {
    const posterUrl = mainImg?.md?.url || '/images/video_placeholder.jpg'

    if (!prefersReducedMotion) {
      return (
        <video
          autoPlay
          loop
          muted
          playsInline
          className={isTransparentMedia}
          preload="none"
          poster={posterUrl}
        >
          <source src={src} type="video/mp4" />
        </video>
      )
    }

    return (
      <HeroHomeImg
        src={mainImg}
        classes={isTransparentMedia}
        isMobile={isMobile}
        isDesktopXL={isDesktopXL}
      />
    )
  }

  return (
    <>
      <div
        className={`hero__home ${classes.root} ${
          settings?.background_filter ? 'filter' : 'no-filter'
        } ${layoutOpt}`}
      >
        <>
          {isVideoMobile && isMobile && videoTag(videoMobile)}
          {isVideoMobile && !isMobile && videoTag(video)}
          {!isVideoMobile && video && videoTag(video)}
          {!isVideoMobile && !video && (
            <HeroHomeImg
              src={mainImg}
              classes={isTransparentMedia}
              isMobile={isMobile}
              isDesktopXL={isDesktopXL}
            />
          )}
        </>
        <Container maxWidth="xl">
          {children}
          <Box
            id="hero__home__wrapper"
            className={
              settings?.header_style
                ? `wrapper ${classes.wrapper} ${classes.wrapperTransparent}`
                : `wrapper ${classes.wrapper}`
            }
          >
            <Grid container spacing={3} className="layout">
              <Grid item md={6} xs={12}>
                <div
                  className={clsx(classes.box, 'hero__home-box')}
                  style={{ color: textColor }}
                >
                  <div className={classes.box__primary} id="hero__box">
                    <Typography
                      variant="h1"
                      className={`title ${classes.title}`}
                      style={{ color: textColor }}
                      component="div"
                      gutterBottom
                    >
                      <CMSText
                        data={settings?.maintitle}
                        defaultText={t('texts:homepage:maintitle')}
                      />
                    </Typography>
                    <Typography
                      variant="body1"
                      className={`tagline ${classes.tagline}`}
                      component="div"
                      style={{ color: textColor }}
                    >
                      <CMSText
                        data={settings?.descriptiontext}
                        defaultText={t('texts:homepage:descriptiontext')}
                      />
                    </Typography>
                    {(!isSubscription || isEnableChooseStartingDate) && (
                      <div className={`date_form ${classes.date_form}`}>
                        <DateRangeForm isLanding={true} t={t} text={text} />
                      </div>
                    )}
                  </div>
                  {ctas &&
                    ctas.length > 0 &&
                    ctas[0]?.cta_link?.url !== undefined &&
                    ctas[0]?.cta_label.length !== 0 && (
                      <div className={`ctas ${classes.box__ctas}`}>
                        {ctas.map((cta, index) => (
                          <Link
                            key={index}
                            href={cta.cta_link.url ? cta.cta_link.url : '/'}
                          >
                            <a>
                              <Button
                                variant={
                                  cta.cta_style ? 'outlined' : 'contained'
                                }
                                color={cta.cta_color ? 'secondary' : 'primary'}
                                component="span"
                                onClick={() =>
                                  trackEvent({
                                    category: `HomePage`,
                                    href: cta?.cta_link?.url,
                                    action: `CTA n°${index} to page ${cta?.cta_link?.url}`,
                                  })
                                }
                              >
                                {prismicText(cta?.cta_label)}
                              </Button>
                            </a>
                          </Link>
                        ))}
                      </div>
                    )}
                  {showStatements &&
                    (isSubscription ? (
                      !isMobile && (
                        <div className={`statements ${classes.box__secondary}`}>
                          <Statements
                            statements={statements}
                            textColor={textColor}
                            t={t}
                          />
                        </div>
                      )
                    ) : (
                      <div
                        className={`statements ${classes.box__secondary}`}
                        style={{ color: textColor }}
                      >
                        <Statements
                          statements={statements}
                          textColor={textColor}
                          t={t}
                        />
                        <Typography className={classes.subnav}>
                          {prismicText(
                            statements?.data?.bottom_sentence,
                            t('texts:homepage:bottom_sentence')
                          )}{' '}
                          <Button
                            className={classes.linkButton}
                            variant={'text'}
                            onClick={() => scrollToDiv('more')}
                            style={{ color: textColor }}
                          >
                            {prismicText(
                              statements?.data?.link,
                              t('texts:homepage:link')
                            )}
                          </Button>
                        </Typography>
                      </div>
                    ))}
                </div>
              </Grid>
              {showPacks &&
                !isCustomProducts &&
                ((packs.loading === true &&
                  typeof packs?.items === 'undefined') ||
                (products.loading === true &&
                  typeof products?.latestProducts === 'undefined') ? (
                  <>
                    <Grid item md={6} xs={12}>
                      <div
                        className={clsx(
                          classes.slide,
                          activePacks
                            ? classes.slideActive
                            : classes.slideInactive
                        )}
                        onMouseOver={handleActivePack}
                        onMouseOut={handleDesActivePack}
                      >
                        {!isMobile ? (
                          <Grid container spacing={3}>
                            {[0, 1, 2].map((item) => {
                              return (
                                <Grid item xs={4} key={item}>
                                  <PackCard isLoading />
                                </Grid>
                              )
                            })}
                          </Grid>
                        ) : (
                          isSubscription && (
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <PackCard isLoading />
                              </Grid>
                            </Grid>
                          )
                        )}
                      </div>
                      {isSubscription && !isProduct && (
                        <div className={classes.buttonToPack}>
                          <Link href={'/packs'}>
                            <a>
                              <Button
                                variant={'contained'}
                                color={'secondary'}
                                size={'medium'}
                                component="span"
                                endIcon={<KeyboardArrowRight />}
                                onClick={() =>
                                  trackEvent({
                                    category: `HomePage`,
                                    href: '/packs',
                                    action: `CTA Hero to /packs`,
                                  })
                                }
                              >
                                {button_to_packs ? button_to_packs : ''}
                              </Button>
                            </a>
                          </Link>
                        </div>
                      )}
                    </Grid>
                  </>
                ) : (
                  <Grid item md={6} xs={12}>
                    <div
                      className={clsx(
                        classes.slide,
                        activePacks
                          ? classes.slideActive
                          : classes.slideInactive
                      )}
                      onMouseOver={handleActivePack}
                      onMouseOut={handleDesActivePack}
                    >
                      {!isSubscription ? (
                        !isMobile && (
                          <Grid container spacing={3}>
                            {isPack && !isProduct ? (
                              <>
                                {packs?.items?.map((pack, index) => {
                                  if (index <= 2) {
                                    return (
                                      <Grid md={4} item key={index}>
                                        <PackCard
                                          pack={pack}
                                          thumbnail_ratio={thumbnail_ratio}
                                          price_pack={price_pack}
                                          isPack={true}
                                        />
                                      </Grid>
                                    )
                                  }
                                })}
                              </>
                            ) : (
                              <>
                                {products?.latestProducts?.length > 0 &&
                                  products?.latestProducts?.map(
                                    (pack, index) => {
                                      if (index <= 2) {
                                        return (
                                          <Grid md={4} item key={index}>
                                            <PackCard
                                              thumbnail_ratio={thumbnail_ratio}
                                              price_pack={price_pack}
                                              pack={pack}
                                            />
                                          </Grid>
                                        )
                                      }
                                    }
                                  )}
                              </>
                            )}
                          </Grid>
                        )
                      ) : isMobile ? (
                        <Glider
                          draggable
                          scrollLock
                          slidesToShow={1}
                          hasDots
                          scrollLockDelay={100}
                          duration={1}
                        >
                          {isPack
                            ? packs?.items?.map((pack, index) => {
                                if (index <= 2) {
                                  return (
                                    <PackCard
                                      key={index}
                                      thumbnail_ratio={thumbnail_ratio}
                                      pack={pack}
                                      price_pack={price_pack}
                                      isPack
                                    />
                                  )
                                }
                              })
                            : products?.latestProducts?.length > 0 &&
                              products?.latestProducts?.map((pack, index) => {
                                if (index <= 2) {
                                  return (
                                    <PackCard
                                      pack={pack}
                                      key={index}
                                      thumbnail_ratio={thumbnail_ratio}
                                      price_pack={price_pack}
                                    />
                                  )
                                }
                              })}
                        </Glider>
                      ) : (
                        <Grid container spacing={3}>
                          {isPack && !isProduct ? (
                            <>
                              {packs?.items?.map((pack, index) => {
                                if (index <= 2) {
                                  return (
                                    <Grid md={4} item key={index}>
                                      <PackCard pack={pack} isPack />
                                    </Grid>
                                  )
                                }
                              })}{' '}
                            </>
                          ) : (
                            <>
                              {products?.latestProducts?.length > 0 &&
                                products?.latestProducts?.map((pack, index) => {
                                  if (index <= 2) {
                                    return (
                                      <Grid md={4} item key={index}>
                                        <PackCard
                                          pack={pack}
                                          thumbnail_ratio={thumbnail_ratio}
                                        />
                                      </Grid>
                                    )
                                  }
                                })}
                            </>
                          )}
                        </Grid>
                      )}
                    </div>
                    {isSubscription
                      ? isPack && (
                          <div className={classes.buttonToPack}>
                            <Link href={'/packs'}>
                              <a>
                                <Button
                                  variant={'contained'}
                                  color={'secondary'}
                                  className={classes.smallerButton}
                                  component="span"
                                  endIcon={<KeyboardArrowRight />}
                                  onClick={() =>
                                    trackEvent({
                                      category: 'HomePage',
                                      href: '/packs',
                                      action: 'HeroHome to pack',
                                    })
                                  }
                                >
                                  {button_to_packs ? button_to_packs : ''}
                                </Button>
                              </a>
                            </Link>
                          </div>
                        )
                      : !isMobile &&
                        isPack &&
                        !isProduct && (
                          <div className={classes.buttonToPack}>
                            <Link href={'/packs'}>
                              <a>
                                <Button
                                  variant={'contained'}
                                  onClick={() =>
                                    trackEvent({
                                      category: 'HomePage',
                                      href: '/packs',
                                      action: 'HeroHome to pack',
                                    })
                                  }
                                  color={'secondary'}
                                  className={classes.smallerButton}
                                  component="span"
                                  endIcon={<KeyboardArrowRight />}
                                >
                                  {button_to_packs ? button_to_packs : ''}
                                </Button>
                              </a>
                            </Link>
                          </div>
                        )}
                    {!isMobile && isProduct && (
                      <div className={classes.buttonToPack}>
                        <Link href={'/shop'}>
                          <a>
                            <Button
                              variant={'contained'}
                              color={'secondary'}
                              onClick={() =>
                                trackEvent({
                                  category: 'HomePage',
                                  href: '/shop',
                                  action: 'HeroHome to shop',
                                })
                              }
                              className={classes.smallerButton}
                              component="span"
                              endIcon={<KeyboardArrowRight />}
                            >
                              {'Voir tous les produits'}
                            </Button>
                          </a>
                        </Link>
                      </div>
                    )}
                  </Grid>
                ))}
              {isCustomProducts && customProducts[0].desc.length > 0 && (
                <>
                  <Grid item md={6} xs={12}>
                    <div
                      className={clsx(
                        classes.slide,
                        activePacks
                          ? classes.slideActive
                          : classes.slideInactive
                      )}
                      onMouseOver={handleActivePack}
                      onMouseOut={handleDesActivePack}
                    >
                      {isMobile ? (
                        <>
                          <Glider
                            draggable
                            scrollLock
                            slidesToShow={1}
                            hasDots
                            scrollLockDelay={100}
                            duration={1}
                          >
                            {settings?.products?.map((pack, index) => {
                              return (
                                <PackCard
                                  key={index}
                                  thumbnail_ratio={thumbnail_ratio}
                                  price_pack={price_pack}
                                  pack={pack}
                                  isCustom
                                  isPack={false}
                                />
                              )
                            })}
                          </Glider>
                        </>
                      ) : isUseCarousel ? (
                        <Grid
                          container
                          spacing={3}
                          className={classes.slideArrow}
                        >
                          <Glider
                            hasArrows
                            draggable
                            scrollLock
                            slidesToShow={3}
                            iconLeft={<KeyboardArrowLeft />}
                            iconRight={<KeyboardArrowRight />}
                            scrollLockDelay={100}
                            duration={1}
                          >
                            {settings?.products?.map((pack, index) => {
                              return (
                                <Grid md={4} item key={index}>
                                  <p>{activePacks}</p>
                                  <Box key={index} p={1.5}>
                                    <PackCard
                                      price_pack={price_pack}
                                      pack={pack}
                                      isCustom
                                      isPack={false}
                                      thumbnail_ratio={thumbnail_ratio}
                                    />
                                  </Box>
                                </Grid>
                              )
                            })}
                          </Glider>
                        </Grid>
                      ) : (
                        <Grid container spacing={3}>
                          {settings?.products?.map((pack, index) => {
                            if (index <= 2) {
                              return (
                                <Grid md={4} item key={index}>
                                  <PackCard
                                    thumbnail_ratio={thumbnail_ratio}
                                    text={text}
                                    price_pack={price_pack}
                                    pack={pack}
                                    isCustom
                                    isPack={false}
                                  />
                                </Grid>
                              )
                            }
                          })}
                        </Grid>
                      )}
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Container>
      </div>
      {isSubscription && showStatements && isMobile && (
        <div
          className={`statements ${
            textColor === '#ffffff' ? classes.invert : ''
          } ${classes.statementsMobile} ${classes.box__secondary}`}
        >
          <Statements statements={statements} t={t} textColor={textColor} />
        </div>
      )}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(HeroHome)
