import React, { useEffect, useMemo } from 'react'
import { InView } from 'react-intersection-observer'
import Head from 'next/head'
import HeroHome from '../components/HomePage/HeroHome'
import Header from '../components/Layout/Header'
import UniversSection from '../components/HomePage/UniversSection'
import InstaWall from '../components/InstaWall/InstaWall'
import { useTranslation } from 'react-i18next'
import { getTranslations } from '../i18n'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import PartnersSection from '../components/HomePage/PartnersSection'
import { connect } from 'react-redux'
import FaqSection from '../components/HomePage/FaqSection'
import MenuSection from '../components/HomePage/MenuSection'
import { useScrollState } from '../utils/scrollState'
import dynamic from 'next/dynamic'
import { isPrismicText, prismicText } from '../components/Global/CMSText'
import { getPrismicDocuments } from '../prismicio'
import { RootState } from '../redux/reducers/rootReducer'
import { PrismicHomePage } from '../interfaces/common/prismicHomePage'
import HrefLangLinks from '../components/HrefLangLinks'
import { PrismicGeneralConfig } from '../interfaces/common/prismicGeneralConfig'

const TestimonialSection = dynamic(
  () => import('../components/HomePage/TestimonialSection')
)
const SharedHead = dynamic(() => import('../components/Global/SharedHead'))
const Footer = dynamic(() => import('../components/Layout/Footer'))
const CommunitySection = dynamic(
  () => import('../components/HomePage/CommunitySection')
)
const Banner = dynamic(() => import('../components/Banner/Banner'))
const SavingsSection = dynamic(
  () => import('../components/HomePage/SavingsSection')
)
const InformationsSection = dynamic(
  () => import('../components/HomePage/InformationsSection')
)
const HowItWorksSection = dynamic(
  () => import('../components/HomePage/HowItWorksSection')
)
const HerosSection = dynamic(
  () => import('../components/HomePage/HerosSection')
)

type Tprops = ReturnType<typeof mapStateToProps> & {
  homepageRes?: PrismicHomePage
  generalRes?: PrismicGeneralConfig
  statements?: any
  instagram?: any
  banner?: any
  parameters: any
  instore: any
  basketRes: any
  accountRes: any
  faqRes: any
  takeback?: any
}

const mapStateToProps = (state: RootState) => ({
  parameters: state.parameters,
})

const Home = ({
  homepageRes,
  generalRes,
  statements,
  banner,
  instore,
  instagram,
  basketRes,
  accountRes,
  faqRes,
  takeback,
  parameters,
}: Tprops) => {
  const inViewOpts = {
    threshold: 0.1,
    triggerOnce: true,
  }
  const { t } = useTranslation()
  const { trackPageView } = useMatomo()
  const scrollState = useScrollState()
  const isBanner = isPrismicText(banner?.data?.text)
  const isEnableChooseStartingDate = parameters?.items?.enableChooseStartingDate
  const hrefLangs = useMemo(
    () => generalRes?.data?.href_languages?.[0]?.text?.split(',') ?? [],
    [generalRes]
  )
  // Track page view
  useEffect(() => {
    trackPageView({ documentTitle: 'HomePage' })
  }, [])

  return (
    <div className="page page--home">
      <SharedHead datas={generalRes} />
      <Head>
        <title key="homePageTitle">
          {prismicText(
            homepageRes?.data?.meta_title_homepage,
            t('texts:general:baseline')
          )}
        </title>
        <meta
          name="title"
          property="og:title"
          content={prismicText(
            homepageRes?.data?.meta_title_homepage,
            t('texts:general:baseline')
          )}
        />
        {homepageRes?.data?.meta_description_homepage &&
          homepageRes?.data?.meta_description_homepage[0]?.text && (
            <meta
              name="description"
              content={prismicText(
                homepageRes?.data?.meta_description_homepage
              )}
            />
          )}
        {homepageRes?.data?.meta_keywords_homepage &&
          homepageRes?.data?.meta_keywords_homepage[0]?.text && (
            <meta
              name="keywords"
              content={prismicText(homepageRes?.data?.meta_keywords_homepage)}
            />
          )}
      </Head>
      <HrefLangLinks locales={hrefLangs} />
      {isBanner && <Banner banner={banner} t={t} />}
      <HeroHome
        settings={homepageRes?.data}
        t={t}
        text={generalRes?.data && generalRes.data}
        isProduct={homepageRes?.data?.show_featured_products}
        statements={statements}
        isEnableChooseStartingDate={isEnableChooseStartingDate}
      >
        {!generalRes?.data?.hide_header && (
          <Header
            isHomePage
            doc={generalRes}
            account={accountRes}
            faq={faqRes}
            t={t}
            logo={generalRes?.data?.logo}
            isTransparent={homepageRes?.data?.header_style}
            logoTransparent={homepageRes?.data?.logo_transparent}
            showOnScrollUp={scrollState.showOnScrollUp}
            showOnTop={scrollState.showOnTop}
            isBanner={isBanner}
            inStoreText={instore}
            textColor={generalRes?.data?.header_text_color}
            background={generalRes?.data?.header_background}
            hoverColor={generalRes?.data?.header_text_hover_color}
            basket={basketRes}
            takeback={takeback}
            submenuBackgroundColor={
              generalRes?.data?.header_submenu_background_color
            }
            submenuTextColor={generalRes?.data?.header_submenu_text_color}
          />
        )}
      </HeroHome>
      <div id="more">
        <div className="flexContent">
          <div
            style={{
              order: homepageRes?.data?.position_how_it_works || 1,
            }}
          >
            {homepageRes?.data?.is_howitworks_active && (
              <InView {...inViewOpts}>
                {({ ref, inView }) => (
                  <div
                    className="fade"
                    ref={ref}
                    style={{ opacity: inView ? 1 : 0 }}
                  >
                    <HowItWorksSection homePage={homepageRes} t={t} />
                  </div>
                )}
              </InView>
            )}
          </div>
          <div
            style={{
              order: homepageRes?.data?.position_faq || 1,
            }}
          >
            {homepageRes?.data?.is_faq_active && (
              <InView {...inViewOpts}>
                {({ ref, inView }) => (
                  <div
                    className="fade"
                    ref={ref}
                    style={{ opacity: inView ? 1 : 0 }}
                  >
                    <FaqSection doc={homepageRes} page={'HomePage'} />
                  </div>
                )}
              </InView>
            )}
          </div>
          <div
            style={{
              order: homepageRes?.data?.position_savings || 1,
            }}
          >
            {homepageRes?.data?.is_savings_active && (
              <InView {...inViewOpts}>
                {({ ref, inView }) => (
                  <div
                    className="fade"
                    ref={ref}
                    style={{ opacity: inView ? 1 : 0 }}
                  >
                    <SavingsSection homePage={homepageRes} t={t} />
                  </div>
                )}
              </InView>
            )}
          </div>
          <div
            style={{
              order: homepageRes?.data?.position_heros || 1,
            }}
          >
            {homepageRes?.data?.is_herosections_active && (
              <InView {...inViewOpts}>
                {({ ref, inView }) => (
                  <div
                    className="fade"
                    ref={ref}
                    style={{ opacity: inView ? 1 : 0 }}
                  >
                    <HerosSection homePage={homepageRes} t={t} />
                  </div>
                )}
              </InView>
            )}
          </div>
          <div
            style={{
              order: homepageRes?.data?.position_univers || 1,
            }}
          >
            {homepageRes?.data?.is_universe_active && (
              <InView {...inViewOpts}>
                {({ ref, inView }) => (
                  <div
                    className="fade"
                    ref={ref}
                    style={{ opacity: inView ? 1 : 0 }}
                  >
                    <UniversSection
                      homePage={homepageRes}
                      taxonLimit={homepageRes?.data?.taxon_number_limit || 6}
                      isPack={generalRes?.data?.pack_page || false}
                      settings={generalRes?.data}
                      t={t}
                    />
                  </div>
                )}
              </InView>
            )}
          </div>
          <div
            style={{
              order: homepageRes?.data?.position_information || 1,
            }}
          >
            {homepageRes?.data?.is_information_active && (
              <InView {...inViewOpts}>
                {({ ref, inView }) => (
                  <div
                    className="fade"
                    ref={ref}
                    style={{ opacity: inView ? 1 : 0 }}
                  >
                    <InformationsSection homePage={homepageRes} t={t} />
                  </div>
                )}
              </InView>
            )}
          </div>
          <div
            style={{
              order: homepageRes?.data?.position_more_information || 1,
            }}
          >
            {homepageRes?.data?.is_more_information_active && (
              <InView {...inViewOpts}>
                {({ ref, inView }) => (
                  <div
                    className="fade"
                    ref={ref}
                    style={{ opacity: inView ? 1 : 0 }}
                  >
                    <InformationsSection
                      isMoreInfo
                      homePage={homepageRes}
                      t={t}
                    />
                  </div>
                )}
              </InView>
            )}
          </div>
          <div
            style={{
              order: homepageRes?.data?.position_testimonials || 1,
            }}
          >
            {homepageRes?.data?.is_testimonials_active && (
              <InView {...inViewOpts}>
                {({ ref, inView }) => (
                  <div
                    className="fade"
                    ref={ref}
                    style={{ opacity: inView ? 1 : 0 }}
                  >
                    <TestimonialSection homePage={homepageRes} t={t} />
                  </div>
                )}
              </InView>
            )}
          </div>
          <div
            style={{
              order: homepageRes?.data?.position_community || 1,
            }}
          >
            {homepageRes?.data?.is_community_active && (
              <InView {...inViewOpts}>
                {({ ref, inView }) => (
                  <div
                    className="fade"
                    ref={ref}
                    style={{ opacity: inView ? 1 : 0 }}
                  >
                    <CommunitySection homePage={homepageRes} t={t} />
                  </div>
                )}
              </InView>
            )}
          </div>
          <div
            style={{
              order: homepageRes?.data?.position_instawall || 1,
            }}
          >
            {homepageRes?.data?.is_instagram_active && instagram && (
              <InView {...inViewOpts}>
                {({ ref, inView }) => (
                  <div
                    className="fade"
                    ref={ref}
                    style={{ opacity: inView ? 1 : 0 }}
                  >
                    <InstaWall data={instagram.data} t={t} />
                  </div>
                )}
              </InView>
            )}
          </div>
          <div
            style={{
              order: homepageRes?.data?.position_partners || 1,
            }}
          >
            {homepageRes?.data?.is_partners_active && (
              <InView {...inViewOpts}>
                {({ ref, inView }) => (
                  <div
                    className="fade"
                    ref={ref}
                    style={{ opacity: inView ? 1 : 0 }}
                  >
                    <PartnersSection data={homepageRes?.data} />
                  </div>
                )}
              </InView>
            )}
          </div>
          <div
            style={{
              order: homepageRes?.data?.position_menu || 1,
            }}
          >
            {homepageRes?.data?.is_menu_active && (
              <InView {...inViewOpts}>
                {({ ref, inView }) => (
                  <div
                    className="fade"
                    ref={ref}
                    style={{ opacity: inView ? 1 : 0 }}
                  >
                    <MenuSection homePage={homepageRes?.data} />
                  </div>
                )}
              </InView>
            )}
          </div>
        </div>
        {homepageRes?.data?.display_take_back_faq && (
          <InView {...inViewOpts}>
            {({ ref, inView }) => (
              <div
                className="fade"
                ref={ref}
                style={{ opacity: inView ? 1 : 0 }}
              >
                <FaqSection doc={homepageRes} isTakeBack page={'HomePage'} />
              </div>
            )}
          </InView>
        )}
      </div>
      {!generalRes?.data?.hide_footer && (
        <>
          <Footer doc={generalRes} t={t} />
        </>
      )}
    </div>
  )
}

export async function getStaticProps({ locale, previewData }) {
  const documents = {
    homepageRes: 'homepage',
    generalRes: 'general',
    statements: 'statements',
    banner: 'banner',
    instore: 'instore',
    instagram: 'instagramwall',
    basketRes: 'basket',
    accountRes: 'account',
    takeback: 'take_back',
    faqRes: 'faq',
  }

  const prismic = await getPrismicDocuments(documents, locale, previewData)

  return {
    props: {
      ...prismic,
      i18nResources: await getTranslations(locale, ['texts']),
    },
    revalidate: 300,
  }
}

export default connect(mapStateToProps)(Home)
