import * as packsService from '../services/packs'

export const ACTIONS = {
  GET_PACKS_TAXONS_REQUEST: 'GET_PACKS_TAXONS_REQUEST',
  GET_PACKS_TAXONS_SUCCESS: 'GET_PACKS_TAXONS_SUCCESS',
  GET_PACKS_TAXONS_FAILURE: 'GET_PACKS_TAXONS_FAILURE',
  GET_PACKS_BY_PACK_TAXON_ID_REQUEST: 'GET_PACKS_BY_PACK_TAXON_ID_REQUEST',
  GET_PACKS_BY_PACK_TAXON_ID_SUCCESS: 'GET_PACKS_BY_PACK_TAXON_ID_SUCCESS',
  GET_PACKS_BY_PACK_TAXON_ID_FAILURE: 'GET_PACKS_BY_PACK_TAXON_ID_FAILURE',
  SAVE_CURRENT_PACK: 'SAVE_CURRENT_PACK',
  SAVE_PACK_ORIGIN: 'SAVE_PACK_ORIGIN',
  DELETE_PACK_ORIGIN: 'DELETE_PACK_ORIGIN',
}

export const GET_PACKS_TAXONS_REQUEST = () => ({
  type: ACTIONS.GET_PACKS_TAXONS_REQUEST,
})

export const GET_PACKS_TAXONS_SUCCESS = (taxonPack) => ({
  type: ACTIONS.GET_PACKS_TAXONS_SUCCESS,
  taxonPack,
})

export const GET_PACKS_TAXONS_FAILURE = (err) => ({
  type: ACTIONS.GET_PACKS_TAXONS_FAILURE,
  err,
})

export function getPackTaxons() {
  return (dispatch) => {
    dispatch(GET_PACKS_TAXONS_REQUEST())

    return packsService.getPacksTaxons().then(
      (response) => dispatch(GET_PACKS_TAXONS_SUCCESS(response)),
      (err) => dispatch(GET_PACKS_TAXONS_FAILURE(err))
    )
  }
}

export const GET_PACKS_BY_PACK_TAXON_ID_REQUEST = () => ({
  type: ACTIONS.GET_PACKS_BY_PACK_TAXON_ID_REQUEST,
})

export const GET_PACKS_BY_PACK_TAXON_ID_SUCCESS = (packs) => ({
  type: ACTIONS.GET_PACKS_BY_PACK_TAXON_ID_SUCCESS,
  packs,
})

export const GET_PACKS_BY_PACK_TAXON_ID_FAILURE = (err) => ({
  type: ACTIONS.GET_PACKS_BY_PACK_TAXON_ID_FAILURE,
  err,
})

export function getPacksByPackTaxonId(id) {
  return (dispatch) => {
    dispatch(GET_PACKS_BY_PACK_TAXON_ID_REQUEST())

    return packsService.getPacksByPackTaxonId(id).then(
      (response) => dispatch(GET_PACKS_BY_PACK_TAXON_ID_SUCCESS(response)),
      (err) => dispatch(GET_PACKS_BY_PACK_TAXON_ID_FAILURE(err))
    )
  }
}

export const SAVE_CURRENT_PACK = (currentPack) => ({
  type: ACTIONS.SAVE_CURRENT_PACK,
  currentPack,
})

export function saveCurrentPack(currentPack) {
  return (dispatch) => {
    dispatch(SAVE_CURRENT_PACK(currentPack))
  }
}

export const SAVE_PACK_ORIGIN = (origin) => ({
  type: ACTIONS.SAVE_PACK_ORIGIN,
  origin,
})

export function savePackOrigin(origin) {
  return (dispatch) => {
    dispatch(SAVE_PACK_ORIGIN(origin))
  }
}

export const DELETE_PACK_ORIGIN = () => ({
  type: ACTIONS.DELETE_PACK_ORIGIN,
})

export function deletePackOrigin() {
  return (dispatch) => {
    dispatch(DELETE_PACK_ORIGIN())
  }
}
