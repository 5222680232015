import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { prismicText } from '../Global/CMSText'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      maxWidth: 320,
      margin: 'auto',
      [theme.breakpoints.up('md')]: {
        margin: 0,
        maxWidth: 500,
        height: 125,
      },
    },
    item: {
      height: '100%',
      textAlign: 'center',
      justifyContent: 'center',
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.common.white,
      border: '1px solid currentColor',
    },
    img: {
      display: 'block',
      margin: 'auto',
      marginBottom: theme.spacing(1),
      height: 32,
      [theme.breakpoints.up('md')]: {
        height: 48,
      },
      objectFit: 'contain',
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      lineHeight: 1,
      fontWeight: 400,
      fontSize: 12,
      minHeight: 36,
      [theme.breakpoints.up('md')]: {
        fontSize: 14,
        minHeight: 42,
      },
      color: 'currentColor',
    },
  })
)

type Tprops = {
  statements: any
  textColor: string
  t: any
}

const Statements = ({ statements, textColor, t }: Tprops) => {
  const classes = useStyles()
  return (
    <Grid container spacing={1} className={classes.container}>
      {statements
        ? statements.data.statements?.map((statement) => (
            <Grid item xs={4} key={`state-${prismicText(statement?.title)}`}>
              <div className={classes.item} style={{ color: textColor }}>
                <img src={statement.icon.url} className={classes.img} alt="" />
                <Typography
                  variant="caption"
                  component="h2"
                  className={classes.title}
                >
                  {prismicText(statement?.title)}
                </Typography>
              </div>
            </Grid>
          ))
        : [0, 1, 2].map((index) => (
            <Grid
              item
              xs={4}
              key={`state-${index}-${t('texts:statement.title')}`}
            >
              <div className={classes.item} style={{ color: textColor }}>
                <img
                  src={'/images/placeholder.jpg'}
                  className={classes.img}
                  alt="Here is an image"
                />
                <Typography
                  variant="caption"
                  component="h2"
                  className={classes.title}
                >
                  {t('texts:statement:title')}
                </Typography>
              </div>
            </Grid>
          ))}
    </Grid>
  )
}

export default Statements
