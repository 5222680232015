import React from 'react'
import clsx from 'clsx'
import {
  makeStyles,
  useTheme,
  Theme,
  useMediaQuery,
  createStyles,
  Grid,
  GridList,
  GridListTile,
  Typography,
  Container,
} from '@material-ui/core'

import Button from '../Button/Button'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import CMSText from '../Global/CMSText'

const moduleHeight = 218

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginNull: {
      margin: 0,
    },
    paddingNull: {
      padding: 0,
    },
    padding: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    instaWall: {
      position: 'relative',
      minHeight: moduleHeight * 1.5,
    },
    insta: {
      minHeight: moduleHeight,
      maxWidth: 407,
      minWidth: 300,
      padding: 5,
      borderRadius: theme.shape.borderRadius,
      position: 'absolute',
      backgroundColor: theme.palette.common.white,
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      [theme.breakpoints.up('lg')]: {
        minWidth: 407,
      },
    },
    instaBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid ${theme.palette.primary.main}`,
      minHeight: 218,
      borderRadius: theme.shape.borderRadius * 0.75,
      textAlign: 'center',
      padding: '10px',
    },
    share: {
      [theme.breakpoints.up('md')]: {
        padding: 10,
      },
    },
    logoInsta: {
      paddingBottom: '10px',
      width: 50,
    },
    gridImg: {
      objectFit: 'cover',
    },
  })
)

type Tprops = {
  isSmall?: boolean
  data?: any
  t?: any
  doc?: any
}

const InstaWall = ({ isSmall = false, data, t }: Tprops) => {
  const classes = useStyles()
  const theme = useTheme()
  const images = data?.instagramimages
  const { trackEvent } = useMatomo()
  return (
    <div className={`section-instawall ${classes.instaWall}`}>
      <Container
        maxWidth="xl"
        className={isSmall ? classes.paddingNull : classes.padding}
      >
        {images && (
          <Grid container justify="center">
            {!isSmall ? (
              <GridList
                cellHeight={154}
                cols={useMediaQuery(theme.breakpoints.up('md')) ? 7 : 2}
                spacing={9}
              >
                {useMediaQuery(theme.breakpoints.up('md'))
                  ? images.map((tile, index) => (
                      <GridListTile key={index} cols={1}>
                        <img
                          className={classes.gridImg}
                          src={tile.image.url}
                          alt={tile.image.alt === null ? '' : tile.image.alt}
                        />
                      </GridListTile>
                    ))
                  : images.slice(0, 4).map((tile, index) => (
                      <GridListTile key={index} cols={1}>
                        <img
                          className={classes.gridImg}
                          src={tile.image.url}
                          alt={tile.image.alt === null ? '' : tile.image.alt}
                        />
                      </GridListTile>
                    ))}
              </GridList>
            ) : (
              <GridList cellHeight={154} cols={2} spacing={9}>
                {images.slice(0, 4).map((tile, index) => (
                  <GridListTile key={index} cols={1}>
                    <img
                      src={tile.image.url}
                      alt={tile.image.alt === null ? '' : tile.image.alt}
                    />
                  </GridListTile>
                ))}
              </GridList>
            )}
          </Grid>
        )}
        <div className={classes.insta}>
          <div className={classes.instaBox}>
            <img
              src={
                data?.instagramlogo?.url
                  ? data.instagramlogo.url
                  : '/images/placeholder.jpg'
              }
              alt="Instagram"
              className={clsx(classes.logoInsta, 'instagram__logo')}
            />
            <Typography variant="h4" component="h2">
              <CMSText
                asText
                data={data?.title}
                defaultText={t('texts:instagramwall:title')}
              />
            </Typography>
            <div className={classes.share}>
              <Typography variant="body2" color={'secondary'} component="div">
                <CMSText
                  asText
                  data={data?.desc}
                  defaultText={t('texts:instagramwall:desc')}
                />
              </Typography>
            </div>
            {data?.buttontext[0]?.text && (
              <Button
                size={
                  useMediaQuery(theme.breakpoints.down('md'))
                    ? 'small'
                    : 'large'
                }
                variant="contained"
                className="instaWall__instaButton"
                onClick={() =>
                  trackEvent({
                    category: 'HomePage',
                    href: data?.link?.url,
                    action: `Universe Section leave website to instagram profile page`,
                  })
                }
                color="primary"
                href={data?.link?.url ? data.link.url : '/'}
                target="blank"
              >
                <CMSText
                  asText
                  data={data?.buttontext}
                  defaultText={t('texts:instagramwall:buttontext')}
                />
              </Button>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default InstaWall
