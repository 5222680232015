import axiosWrapper from '../helpers/api'
import { ERequestUrl, EMethods } from '../helpers/requests'

export const getPacksTaxons = () =>
  axiosWrapper({
    method: EMethods.get,
    url: ERequestUrl.taxonPack,
  })

export const getPacksByPackTaxonId = (id) =>
  axiosWrapper({
    method: EMethods.get,
    url: ERequestUrl.packs,
    uid: `${id}`,
  })
