import { makeStyles, createStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) =>
  createStyles({
    color_white: {
      color: theme.palette.common.white,
    },
    invert: {
      filter: 'invert(1)',
    },
    root: {
      zIndex: 2,
      position: 'relative',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      minHeight: '80vh',
      justifyContent: 'center',
      textAlign: 'center',
      overflow: 'hidden',
      '&.filter': {
        '&::after': {
          zIndex: 1,
          position: 'absolute',
          content: "''",
          height: '100%',
          width: '100%',
          top: 0,
          bottom: 0,
          left: 0,
          opacity: 0.6,
          background:
            'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 72.15%, #000000 100%)',
        },
      },
      '&.center': {
        '& .layout': {
          [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
            textAlign: 'center',
            '& .MuiGrid-grid-md-6': {
              maxWidth: '100%',
              flexBasis: '100%',
              '& +.MuiGrid-grid-md-6>div': {
                maxWidth: 680,
                margin: 'auto',
              },
            },
            '& .tagline, & .statements > .MuiGrid-root': {
              marginLeft: 'auto',
              marginRight: 'auto',
            },
            '& .date_form, & .ctas': {
              justifyContent: 'center',
            },
          },
        },
      },
      '&.left': {
        '&.filter:after': {
          [theme.breakpoints.up('md')]: {
            left: 0,
            background:
              'linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.8) 42.42%, rgba(37, 24, 56, 0) 100%)',
          },
        },
        '& .MuiBox-root': {
          [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start',
            textAlign: 'left',
            '& .date_form': {
              justifyContent: 'flex-start',
            },
          },
        },
      },
      '&.right': {
        '&.filter:after': {
          [theme.breakpoints.up('md')]: {
            height: '100%',
            left: 'auto',
            right: 0,
            background:
              'linear-gradient(-90deg, #000000 0%, rgba(0, 0, 0, 0.8) 42.42%, rgba(37, 24, 56, 0) 100%)',
          },
        },
        '& .layout': {
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row-reverse',
            textAlign: 'left',
            '& .date_form': {
              justifyContent: 'flex-start',
            },
          },
        },
      },
    },
    media: {
      zIndex: -100,
      position: 'absolute',
      overflow: 'hidden',
      left: 0,
      top: theme.sizes.headerHeight,
      right: 0,
      bottom: 0,
      width: '100%',
      height: `calc(100% - ${theme.sizes.headerHeight}px)`,
      objectFit: 'cover',
      backgroundColor: theme.palette.common.black,
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
    },
    mediaTransparent: {
      top: 0,
      height: '100%',
    },
    wrapper: {
      zIndex: 3,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: theme.sizes.headerHeight,
      minHeight: `calc(100vh - ${theme.sizes.headerHeight}px)`,
      [theme.breakpoints.up('md')]: {
        minHeight: `calc(100vh - ${theme.sizes.headerHeight * 2}px)`,
      },
    },
    wrapperTransparent: {
      paddingTop: theme.sizes.headerHeightTransparent,
      minHeight: `calc(100vh - ${theme.sizes.headerHeightTransparent}px)`,
      [theme.breakpoints.up('md')]: {
        minHeight: `calc(100vh - ${theme.sizes.headerHeightTransparent * 2}px)`,
      },
    },
    box: {},
    box__primary: {},
    box__secondary: {
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(4),
      },
    },
    box__ctas: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
      '&>a': {
        display: 'inline-block',
        [theme.breakpoints.down('md')]: {
          margin: theme.spacing(1),
        },
        [theme.breakpoints.up('md')]: {
          margin: 0,
          '&+a': {
            margin: theme.spacing(0, 2),
          },
        },
      },
    },
    title: {
      fontSize: theme.typography.pxToRem(24),
      '& .paragraph-class': {
        margin: 0,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(30),
      },
    },
    tagline: {
      lineHeight: 1.2,
      fontSize: theme.typography.pxToRem(12),
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(15),
        maxWidth: '75%',
        lineHeight: 'inherit',
      },
    },
    buttonToPack: {
      marginTop: theme.spacing(4),
      width: '100%',
      textAlign: 'center',
    },
    slide: {
      padding: theme.spacing(0, 5),
      [theme.breakpoints.down('sm')]: {
        position: 'relative',
        width: '100vw',
        transform: 'translateX(-50%) translateY(0)',
        left: '50%',
        marginBottom: theme.spacing(2),
      },
      '& .MuiBox-root': {
        transition: theme.transitions.create('opacity'),
      },
    },
    slideArrow: {
      '& .glider-next': {
        right: -40,
      },
      '& .glider-prev': {
        left: -40,
      },
    },
    slideActive: {
      opacity: 1,
      '& .MuiBox-root': {
        opacity: 0.75,
        '&:hover': {
          opacity: 1,
        },
      },
    },
    slideInactive: {
      opacity: 1,
    },
    smallerButton: {
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(12),
        padding: theme.spacing(1, 2),
      },
    },
    statementsMobile: {
      marginTop: theme.spacing(2),
    },
    date_form: {
      display: 'flex',
      textAlign: 'left',
      justifyContent: 'center',
      margin: theme.spacing(3, 0, 8),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(8, 0, 3),
        padding: theme.spacing(2, 1.5),
        background: theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
      },
    },
    subnav: {
      fontSize: theme.typography.pxToRem(14),
      paddingLeft: theme.spacing(1.5),
      //color: theme.palette.common.white,
      '& a': {
        color: 'currentColor',
        fontWeigth: 700,
        textDecoration: 'underline',
        '&:hover, &:focus': {
          textDecoration: 'none',
        },
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(10),
      },
    },
    linkButton: {
      padding: 0,
      color: '#fff',
      fontSize: theme.typography.pxToRem(14),
      textDecoration: 'underline',
      margin: 0,
      '&:hover': {
        background: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(10),
      },
    },
  })
)

export default useStyles
